import { element } from 'prop-types'
// Run this example by adding <%= javascript_pack_tag 'financial_reports' %> to your desired file.

import React from 'react'
import ReactDOM from 'react-dom'
import FinancialReports from '../financial_reports'

const rootElements = document.querySelectorAll('.reports-list')

document.addEventListener('DOMContentLoaded', () => {
  Array.from(rootElements).forEach((element) => {
    const typeId = element.dataset.set
    const filters = element.dataset.filter

    ReactDOM.render(
      <FinancialReports
        setId={typeId}
        showFilters={filters === 'true' ? true : false}
      />,
      element,
    )
  })
})
