import React, { useEffect, useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import ConditionalWrap from 'conditional-wrap'
import { DateTime } from 'luxon'
import LuxonUtils from '@date-io/luxon'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const FinancialReportsFilters = (props) => {
  const { currentFilters, filterChange, setId } = props

  const [categories, setCategories] = useState([])
  const [dropdowns, setDropdowns] = useState({
    dates: false,
    categories: false,
  })
  const [dateRange, setDateRange] = useState({
    min: DateTime.fromISO('1995-01-01T00:00:00-0500'),
    max: DateTime.local().plus({ month: 1 }),
  })
  const [windowWidth, setWindowWidth] = useState(0)
  const [openFilters, setOpenFilters] = useState(false)
  const [filters, setFilters] = useState(currentFilters)
  const [selectedCategories, setSelectedCategories] = useState({})

  useEffect(() => {
    window.addEventListener('resize', saveWindowWidth)
    document.addEventListener('load', saveWindowWidth())

    fetch(`/financial_reports/get_categories.json?set=${setId}`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.categories)
      })

    return () => {
      window.removeEventListener('resize', saveWindowWidth)
      document.removeEventListener('load', saveWindowWidth)
    }
  }, [])

  useEffect(() => {
    setFilters(currentFilters)
  }, [currentFilters])

  useEffect(() => {
    if (categories.length) {
      const initialCategories = {}
      categories.forEach((category) => {
        if (!filters.categories.includes(category)) {
          initialCategories[category] = false
        } else {
          initialCategories[category] = true
        }
      })

      setSelectedCategories(initialCategories)
    }
  }, [categories])

  const saveWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  const clearCategories = () => {
    const updatedCategories = { ...selectedCategories }

    const keys = Object.keys(updatedCategories)
    for (const key of keys) {
      updatedCategories[key] = false
    }

    setSelectedCategories(updatedCategories)
    filterChange({
      ...filters,
      categories: [],
    })

    closeDropdrowns()
  }

  const saveCategories = () => {
    const selected = []

    categories.forEach((category) => {
      if (selectedCategories[category]) {
        selected.push(category)
      }
    })

    filterChange({
      ...filters,
      categories: selected,
    })

    closeDropdrowns()
  }

  const closeDropdrowns = () => {
    const updatedDropdowns = { ...dropdowns }
    Object.keys(updatedDropdowns).forEach((key) => {
      updatedDropdowns[key] = false
    })

    setDropdowns(updatedDropdowns)
  }

  const clearDateRange = () => {
    const newDates = {
      min: DateTime.fromISO('1995-01-01T00:00:00-0500'),
      max: DateTime.local().plus({ month: 1 }),
    }

    setDateRange(newDates)

    filterChange({
      ...filters,
      date_start: newDates.min,
      date_end: newDates.max,
    })

    closeDropdrowns()
  }

  const saveDateRange = () => {
    filterChange({
      ...filters,
      date_start: dateRange.min,
      date_end: dateRange.max,
    })

    closeDropdrowns()
  }

  const filterObjects = [
    <div key='categories'>
      {categories.length > 0 && (
        <div className='filters__dropdown'>
          <button
            type='button'
            className={dropdowns.categories ? 'open' : ''}
            onClick={() =>
              setDropdowns({
                ...dropdowns,
                categories: !dropdowns.categories,
              })
            }
          >
            Types
          </button>

          <div className='filters__options'>
            <Collapse in={dropdowns.categories}>
              <div className='filters__options__inner'>
                <ul>
                  {categories.map((category) => (
                    <li key={category}>
                      <label className='filters__option'>
                        <input
                          value={selectedCategories[category] || false}
                          checked={selectedCategories[category] || false}
                          type='checkbox'
                          onChange={() => {
                            setSelectedCategories({
                              ...selectedCategories,
                              [category]: !selectedCategories[category],
                            })
                          }}
                        />
                        <span />
                        <span>{category}</span>
                      </label>
                    </li>
                  ))}
                </ul>

                <div className='filters__controls'>
                  <button
                    type='button'
                    className='filters__control filters__control--clear'
                    onClick={clearCategories}
                  >
                    Clear
                  </button>

                  <button
                    type='button'
                    className='filters__control filters__control--save'
                    onClick={saveCategories}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </div>,
    <div className='filters__dropdown' key='dates'>
      <button
        type='button'
        onClick={() => {
          setDropdowns({
            ...dropdowns,
            dates: !dropdowns.dates,
          })
        }}
        className={dropdowns.dates ? 'open' : ''}
      >
        Date Range
      </button>

      <div className='filters__options'>
        <Collapse in={dropdowns.dates}>
          <div className='filters__options__inner'>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <div className='filters__inputs filters__inputs--amount'>
                <label>
                  <span>MIN</span>
                  <DatePicker
                    autoOk={true}
                    variant='inline'
                    openTo='year'
                    views={['year', 'month']}
                    value={dateRange.min || null}
                    format='LLL yyyy'
                    onChange={(event) => {
                      setDateRange({
                        ...dateRange,
                        min: event,
                      })
                    }}
                  />
                </label>

                <label>
                  <span>MAX</span>
                  <DatePicker
                    autoOk={true}
                    variant='inline'
                    openTo='year'
                    views={['year', 'month']}
                    value={dateRange.max || null}
                    format='LLL yyyy'
                    onChange={(event) => {
                      setDateRange({
                        ...dateRange,
                        max: event,
                      })
                    }}
                  />
                </label>
              </div>
            </MuiPickersUtilsProvider>

            <div className='filters__controls'>
              <button
                type='button'
                className='filters__control filters__control--clear'
                onClick={clearDateRange}
              >
                Clear
              </button>

              <button
                type='button'
                className='filters__control filters__control--save'
                onClick={saveDateRange}
              >
                Save
              </button>
            </div>
          </div>
        </Collapse>
      </div>
    </div>,
  ]

  return (
    <div className='filters filters--reports'>
      <button
        className={`filters__open ${openFilters ? 'filters__open--close' : ''}`}
        onClick={() => setOpenFilters(!openFilters)}
      >
        Filter Results
      </button>

      <ConditionalWrap
        condition={windowWidth < 1340}
        wrap={(children) => <Collapse in={openFilters}>{children}</Collapse>}
      >
        {filterObjects}
      </ConditionalWrap>
    </div>
  )
}

export default FinancialReportsFilters
