import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { DateTime } from 'luxon'
import FinancialReportsFilters from './FinancialReportsFilters'

const FinancialReports = (props) => {
  const { showFilters, setId } = props

  const [reports, setReports] = useState([])
  const [filters, setFilters] = useState({
    set: null,
    categories: [],
    date_start: null,
    date_end: null,
  })
  const [filterInit, setFilterInit] = useState(false)

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (parsed.category) {
      setFilters({
        ...filters,
        categories: [parsed.category],
      })
    }

    setFilterInit(true)
  }, [])

  useEffect(() => {
    if (filterInit) {
      let formattedFilters = {
        ...filters,
        set: setId,
      }

      let query = queryString.stringify(formattedFilters, {
        arrayFormat: 'bracket',
      })

      if (filters.date_start && filters.date_end) {
        formattedFilters = {
          ...formattedFilters,
          date_start: filters.date_start.toISO(),
          date_end: filters.date_end.toISO(),
        }

        query = queryString.stringify(formattedFilters, {
          arrayFormat: 'bracket',
        })
      }

      fetch(`/financial_reports/all.json?${query}`)
        .then((response) => response.json())
        .then((data) => {
          setReports(data.reports)
        })
    }
  }, [filters, filterInit])

  const formattedDate = (report) => {
    if (report.yearOnly) {
      return DateTime.fromISO(report.date).toFormat('yyyy')
    } else {
      return DateTime.fromISO(report.date).toFormat('LLLL yyyy')
    }
  }

  return (
    <>
      {setId ? (
        <div>
          {showFilters && (
            <FinancialReportsFilters
              currentFilters={filters}
              filterChange={setFilters}
              setId={setId}
            />
          )}

          <div className='cards cards--reports'>
            {reports.map((report) => (
              <div className='card' key={report.id}>
                <div className='card__details'>
                  <h3>{formattedDate(report)}</h3>
                  <h4>{report.category}</h4>

                  <a
                    href={report.file}
                    target='_blank'
                    className='underlined-link'
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default FinancialReports
